import React from 'react';
import ConnectPageTemplate from '../../../components/Connect/QA';

const subtitles = {
  A: (
    <>
      Tintash is a full service development company that provides managed remote teams from around
      the globe. We work closely with you to design and build your digital products. Our clients
      include several <span className="connect__heading-description-bold">Startups</span>,{' '}
      <span className="connect__heading-description-bold">Unicorns</span> and{' '}
      <span className="connect__heading-description-bold">Fortune 500s.</span>
    </>
  ),
  B:
    'Leverage our testing automation talent to elevate your project, helping you achieve the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <ConnectPageTemplate
      variant={pageContext.variant}
      location={location}
      title="Industry Leading Automated QA testing Company"
      subtitles={subtitles}
      metaTitle="Top 1% QA Testing Company | Tintash"
      metaDescription="Tintash is an industry leading QA testing company providing dedicated remote teams globally. We work closely with you to design and build your digital products. Our clients include Startups, Unicorns and Fortune 500s."
      pageviewTitle="Connect/QA/QA.Testing.Automation"
    />
  );
}

export default index;
